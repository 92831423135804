var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "c-table",
    {
      ref: "table",
      attrs: {
        title:
          "이탈 (" + _vm.popupParam.deviationName + ") 별 가능한 원인 목록",
        columns: _vm.grid.columns,
        gridHeight: _vm.grid.height,
        data: _vm.grid.data,
        filtering: false,
        columnSetting: false,
        usePaging: false,
        editable: _vm.editable,
        selection: "multiple",
        rowKey: "ramVariableGuidewordId",
      },
      on: { rowDblclick: _vm.rowDblclick },
    },
    [
      _c(
        "template",
        { slot: "table-button" },
        [
          _c(
            "q-btn-group",
            { attrs: { outline: "" } },
            [
              _vm.editable
                ? _c("c-btn", {
                    attrs: { label: "선택", icon: "check" },
                    on: { btnClicked: _vm.select },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }